import AccountRoutes from '@marmalade/account/lib/components/account-routes';
import PrivateRoute from '@marmalade/account/lib/components/private-route';
import { paths as accountPaths } from '@marmalade/account/lib/constants';
import { fetchIsInternal } from '@marmalade/is-internal';
import Account from '@marmalade/ldi/lib/customer/components/layout';
import ldiModals from '@marmalade/ldi/lib/modals';
import MtaForm from '@marmalade/ldi/lib/mta/form';
import NewBusinessRoutes from '@marmalade/ldi/lib/new-business/routes';
import RenewalRoutes from '@marmalade/ldi/lib/renewal/routes';
import Modal from '@marmalade/modal/lib/components/modal';
import ModalAnchor from '@marmalade/modal/lib/components/modal-anchor';
import ModalOverlay from '@marmalade/modal/lib/components/modal-overlay';
import ParseQuery from '@marmalade/query';
import ScrollToTop from '@marmalade/scroll-to-top';
import ApplicationLayout from './modules/application-layout';
import DataLayer from './modules/datalayer';
import { DataLayerGa4 } from './modules/datalayer-ga4';
import { RedirectToV5 } from './modules/redirect-to-v5';
import { func } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

const NewBusiness = () => (
    <ApplicationLayout>
        <NewBusinessRoutes />
    </ApplicationLayout>
);

const Mta = () => (
    <ApplicationLayout>
        <MtaForm redirectLink="/account/dashboard" />
    </ApplicationLayout>
);

const Renewal = () => (
    <ApplicationLayout>
        <RenewalRoutes />
    </ApplicationLayout>
);

class Router extends Component {
    static propTypes = {
        fetchIsInternal: func.isRequired,
    };

    componentDidMount() {
        this.props.fetchIsInternal();
    }

    render() {
        return (
            <BrowserRouter>
                <Fragment>
                    <ModalAnchor>
                        <ParseQuery>
                            <DataLayer />
                            <DataLayerGa4 />
                            <ScrollToTop>
                                <Switch>
                                    <Route exact path="/" component={RedirectToV5} />
                                    <Redirect from="/sign-out" to="/login" />
                                    <AccountRoutes
                                        path={accountPaths}
                                        product="ldi"
                                        accountHomeLink="/account/dashboard"
                                        exact
                                    />
                                    <Redirect path={accountPaths} to="/login" />
                                    <Route path="/ldi/new" component={NewBusiness} />
                                    <PrivateRoute path="/ldi/mta" component={Mta} />
                                    <PrivateRoute path="/ldi/renewal" component={Renewal} />
                                    <PrivateRoute path="/account" component={Account} />
                                    <Route component={RedirectToV5} />
                                </Switch>
                            </ScrollToTop>
                        </ParseQuery>
                    </ModalAnchor>
                    <ModalOverlay />
                    <Modal content={ldiModals} />
                </Fragment>
            </BrowserRouter>
        );
    }
}

export default connect(undefined, { fetchIsInternal })(Router);
